<template>
  <div class="parameters">
    <UISelect
      :title="$t('Experience')"
      :instructions="$t('Select the Experience type that you want this coupon to be applicable to.')"
      :value="promo.experience.id"
      @change="$emit('change:experienceId', $event.target.value)"
      required
      rules="required|dropdown"
    >
      <option
        v-for="experience in experiences"
        :key="experience.id"
        :value="experience.id"
      >{{ experience.title }}</option>
    </UISelect>
    <UIInput
      :title="$t('Minimum Number of Stations')"
      :instructions="$t('The minimum number of stations required to be booked for this coupon to apply.')"
      type="number"
      min="0"
      max="99"
      rules="numeric|minValue:0|maxValue:99"
      :value="promo.minStations"
      @input="$emit('input:minStations', $event.target.value)"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIInput from '@/core/shared/components/ui/UIInput';

@Component({
  components: {
    UISelect,
    UIInput,
  },
  props: {
    promo: {
      type: Object,
      required: true,
    },
    experiences: {
      type: Array,
      required: true,
    },
  },
})
export default class PromoParamaters extends Vue {
  created () {
    if (!this.promo.minStations) {
      this.$emit('input:minStations', 0);
    }
  }
}
</script>

<style scope lang="postcss">
@import "core/shared/styles";

.parameters {
  display: grid;
  grid-gap: var(--spacingSm);

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--desktop) {
    grid-gap: var(--spacingMd);
  }
}
</style>
