<template>
  <div class="discount-index">
    <div class="header-bar">
      <UIButton
        :to="{ name: 'discount-show', params: { discountId: 'new' } }"
      >{{ $t('New Discount') }}</UIButton>
    </div>

    <div class="empty f-body tc w-60 center fill-geyser fadeIn animated" v-if="noDiscounts">
      <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
      <i18n tag="p" path="You don't have discounts yet. Click on {newDiscountLink} to create one.">
        <template #newDiscountLink>
          <router-link
            class="link-style"
            :to="{ name: 'discount-show', params: { discountId: 'new' } }"
          >{{ $t('New Discount') }}</router-link>
        </template>
      </i18n>
    </div>

    <UIRowIndexLoader v-if="isLoading" />

    <template v-if="!isLoading && (activeDiscounts.length || inactiveDiscounts.length)">
      <div class="discountgridheader f6 fadeIn animated">
        <div>{{ $t('Discount Name') }}</div>
        <div class="tablet">{{ $t('Experience') }}</div>
        <div class="tablet">{{ $tc('Discount') }}</div>
        <div class="tablet">{{ $t('Dates') }}</div>
      </div>

      <div class="active fadeIn animated" v-if="activeDiscounts.length">
        <div class="discountgridheader f6">
          <div>{{ $t('Active Discounts') }}</div>
        </div>

        <div class="discountgrid">
          <div class="discountcard f6" v-for="discount in activeDiscounts" :key="discount.id">
            <div class="title f4 nowrap dib truncate slate">{{ discount.title }}</div>
            <div class="experience tablet">{{ discount.experience.title }}</div>
            <div class="discount tablet">{{ discount.displayDiscount }}</div>
            <div class="dates tablet">
              <span v-if="discount.startsAt">{{ formatDate(discount.startsAt) }}</span>
              <span v-if="discount.endsAt">- {{ formatDate(discount.endsAt) }}</span>
              <div v-if="!discount.startAt && !discount.endsAt">{{ $t('Forever') }}</div>
            </div>
            <div class="actions">
              <UIButton
                tiny
                icon="edit"
                :to="{ name: 'discount-show', params: { discountId: discount.id } }"
              >{{ $t("edit") }}</UIButton>
            </div>
          </div>
        </div>
      </div>

      <div class="inactive fadeIn animated" v-if="inactiveDiscounts.length">
        <div class="discountgridheader f6">
          <div>{{ $t('Inactive Discounts') }}</div>
        </div>

        <div class="discountgrid">
          <div class="discountcard f6" v-for="discount in inactiveDiscounts" :key="discount.id">
            <div class="title f4 nowrap dib truncate slate">{{ discount.title }}</div>
            <div class="experience tablet">{{ discount.experience.title }}</div>
            <div class="discount tablet">{{ discount.displayDiscount }}</div>
            <div class="dates tablet">
              <span v-if="discount.startsAt">{{ formatDate(discount.startsAt) }}</span>
              <span v-if="discount.endsAt">- {{ formatDate(discount.endsAt) }}</span>
              <div v-if="!discount.startAt && !discount.endsAt">{{ $t('Forever') }}</div>
            </div>
            <div class="actions">
              <UIButton
                tiny
                icon="edit"
                :to="{ name: 'discount-show', params: { discountId: discount.id } }"
              >{{ $t("edit") }}</UIButton>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';

@Component({
  components: {
    UIButton,
    UIIcon,
    UIRowIndexLoader,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
})
export default class DiscountIndexRoute extends Vue {
  get isLoading () {
    return this.$store.state.promos.isFetchingPromos && this.discounts.length === 0;
  }

  get noDiscounts () {
    return !this.$store.state.promos.isFetchingPromos && this.discounts.length === 0;
  }

  get discounts () {
    return this.$store.getters['entities/Discount'](({ location }) => location.id === this.locationId);
  }

  get activeDiscounts () {
    return this.discounts.filter(discount => discount.active).sort((a, b) => {
      if (!a.endsAt && !b.endsAt) return 0;
      if (!a.endsAt) return 1;
      if (!b.endsAt) return -1;
      return Date.parse(a.endsAt) - Date.parse(b.endsAt);
    });
  }

  get inactiveDiscounts () {
    return this.discounts.filter(discount => !discount.active).sort((a, b) => {
      if (!a.endsAt && !b.endsAt) return 0;
      if (!a.endsAt) return 1;
      if (!b.endsAt) return -1;
      return Date.parse(a.endsAt) - Date.parse(b.endsAt);
    });
  }

  created () {
    this.$store.commit('setTitle', this.$t('Discounts'));
  }

  mounted () {
    this.$store.dispatch('getLocationDiscounts', this.locationId);
  }

  formatDate (date) {
    return moment(date).format('LL');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.discount-index {
  @apply --container;

  & .header-bar {
    margin: var(--spacingMd) 0 var(--spacingSm) 0;
    text-align: right;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & button {
      @apply --buttonPrimary;

      display: inline-block;
      width: auto;
    }
  }

  & h2 {
    @apply --f5;
    margin: var(--spacingMd) 0;
  }

  & .discountgridheader {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    grid-gap: var(--spacingSm);
    padding: 0 var(--spacingSm);
    margin-bottom: var(--spacingSm);

    @media (--tablet) {
      grid-gap: var(--spacingMd);
      padding: 0 var(--spacingAltMd);
      grid-template-columns: 15rem 1fr 1fr 1fr 5.5rem;

      & > * {
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }
    }
  }

  & .discountgrid {
    display: grid;
    grid-gap: var(--spacingSm);

    & .discountcard {
      background-color: var(--colorWhite);
      box-shadow: var(--shadowSm);
      border-radius: var(--radius);

      display: grid;
      grid-template-columns: auto 5.5rem;
      align-items: center;
      grid-gap: var(--spacingSm);
      padding: var(--spacingSm);

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        padding: var(--spacingAltSm) var(--spacingAltMd);
        grid-template-columns: 15rem 1fr 1fr 1fr 5.5rem;

        & > * {
          text-align: center;

          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }

  & .active {
    margin-bottom: var(--spacingLg);
  }
}
</style>
