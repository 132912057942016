<template>
  <div>
    <div class="timing">
      <UIButton
        :ghost="true"
        :class="{ active: timing === 'always' }"
        @click="timing = 'always'"
      >{{ $t('Always Active') }}</UIButton>
      <UIButton
        :ghost="true"
        :class="{ active: timing === 'dates' }"
        @click="timing = 'dates'"
      >{{ $t('Start/End Date') }}</UIButton>
    </div>
    <StartEndDate
      :promo="promo"
      @change:timing="changeTiming"
      :timing="timing"
      :location="location"
      v-if="timing === 'dates'"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import StartEndDate from '../components/StartEndDate';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    StartEndDate,
    UIButton,
  },
  props: {
    promo: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },
})
export default class PromoTiming extends Vue {
  timing = 'always';

  created () {
    if (this.promo.startsAt) {
      this.timing = 'dates';
    }
  }

  changeTiming (property, value) {
    this.$emit('change:timing', property, value);
  }
}
</script>

<style scope lang="postcss">
@import "core/shared/styles";

.timing {
  display: flex;
  & > *:first-child {
    margin-right: var(--spacingSm);
  }
}
</style>
