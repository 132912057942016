<template>
  <div class="details">
    <UISelect :title="$t('Type')" v-model="promo.type" @change="onTypeChanged($event.target.value)">
      <option value="percent">{{ $t('Percent Off') }}</option>
      <option value="amount">{{ $t('Flat Amount Off') }}</option>
    </UISelect>
    <UIInput
      type="number"
      :rules="`${promo.type === 'amount' ? 'decimal:2' : 'numeric'}|minValue:0|maxValue:${promo.type === 'amount' ? 999 : 100}`"
      :step="`${promo.type === 'amount' ? 0.01 : 1}`"
      min="0"
      :max="promo.type === 'amount' ? 999 : 100"
      :title="$t('Amount Off')"
      @input="setTempAmountOff($event.target.value)"
      @change="changeTempAmountOff($event.target.value)"
      :value="tempAmountOff"
    />
    <slot />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIInput from '@/core/shared/components/ui/UIInput';

@Component({
  components: {
    UISelect,
    UIInput,
  },
  props: {
    promo: {
      type: Object,
      required: true,
    },
  },
})
export default class PromoDetails extends Vue {
  tempAmountOff = 0;

  created () {
    this.changeTempAmountOff(this.promo.type === 'amount' ? this.promo.amount / 100 : this.promo.amount);
  }

  onTypeChanged (type) {
    switch (type) {
    case 'amount': {
      const parsedTempAmountOff = parseInt(this.tempAmountOff);
      this.tempAmountOff = !Number.isNaN(parsedTempAmountOff) ? parsedTempAmountOff.toFixed(2) : this.tempAmountOff;
      break;
    }
    case 'percent':
      this.tempAmountOff = Math.trunc(this.tempAmountOff);
      break;
    }
  }

  // NOTE(Jack): This should only be used for the input listener on the 'Amount Off' input
  setTempAmountOff (amount) {
    this.tempAmountOff = amount;
  }

  // NOTE(Jack): This should be used when the 'Amount Off' input has been confirmed (either by the user or from the component)
  changeTempAmountOff (amount) {
    if (this.promo.type === 'percent') {
      this.tempAmountOff = amount;
      return;
    }

    const parsedTempAmountOff = parseFloat(amount);
    // NOTE(Jack): This just ensures that there is always a floating point in the amount
    this.tempAmountOff = !Number.isNaN(parsedTempAmountOff) ? parsedTempAmountOff.toFixed(2) : amount;
  }

  formatAmountForStore () {
    // NOTE(Jack): Due to floating point error, I round the resulting coupon amount to remove any epsilon error that remains
    return this.promo.type === 'amount' ? Math.round(this.tempAmountOff * 100) : this.tempAmountOff;
  }
}
</script>

<style scope lang="postcss">
@import "core/shared/styles";

.details {
  display: grid;
  grid-gap: var(--spacingSm);

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--desktop) {
    grid-gap: var(--spacingMd);
  }
}
</style>
