<template>
  <div class="coupon-index">
    <div class="header-bar">
      <UIButton :to="{ name: 'coupon-show', params: { couponId: 'new' } }">{{ $t('New Coupon') }}</UIButton>
    </div>

    <div class="empty f-body tc w-60 center fill-geyser fadeIn animated" v-if="noCoupons">
      <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
      <i18n tag="p" path="You don't have coupons yet. Click on {newCouponLink} to create one.">
        <template #newCouponLink>
          <router-link
            class="link-style"
            :to="{ name: 'coupon-show', params: { couponId: 'new' } }"
          >{{ $t('New Coupon') }}</router-link>
        </template>
      </i18n>
    </div>

    <UIRowIndexLoader v-if="isLoading" />

    <template v-if="!isLoading && (activeCoupons.length || inactiveCoupons.length)">
      <div class="coupongridheader f6 fadeIn animated">
        <div>{{ $t('Coupon Code') }}</div>
        <div class="tablet">{{ $t('Experience') }}</div>
        <div class="tablet">{{ $tc('Discount') }}</div>
        <div class="tablet">{{ $t('Dates') }}</div>
      </div>

      <div class="active fadeIn animated" v-if="activeCoupons.length">
        <div class="coupongridheader f6">
          <div>{{ $t('Active Coupons') }}</div>
        </div>

        <div class="coupongrid">
          <div class="couponcard f6" v-for="coupon in activeCoupons" :key="coupon.id">
            <div class="title f4 nowrap dib truncate slate">{{ coupon.code }}</div>
            <div class="experience tablet">{{ coupon.experience.title }}</div>
            <div class="discount tablet">{{ coupon.displayCoupon }}</div>
            <div class="dates tablet">
              <span v-if="coupon.startsAt">{{ formatDate(coupon.startsAt) }}</span>
              <span v-if="coupon.endsAt">- {{ formatDate(coupon.endsAt) }}</span>
              <div v-if="!coupon.startAt && !coupon.endsAt">{{ $t('Forever') }}</div>
            </div>
            <div class="actions">
              <UIButton
                tiny
                icon="edit"
                :to="{ name: 'coupon-show', params: { couponId: coupon.id } }"
              >{{ $t("edit") }}</UIButton>
            </div>
          </div>
        </div>
      </div>

      <div class="inactive fadeIn animated" v-if="inactiveCoupons.length">
        <div class="coupongridheader f6">
          <div>{{ $t('Inactive Coupons') }}</div>
        </div>

        <div class="coupongrid">
          <div class="couponcard f6" v-for="coupon in inactiveCoupons" :key="coupon.id">
            <div class="title f4 nowrap dib truncate slate">{{ coupon.code }}</div>
            <div class="experience tablet">{{ coupon.experience.title }}</div>
            <div class="discount tablet">{{ coupon.displayCoupon }}</div>
            <div class="dates tablet">
              <span v-if="coupon.startsAt">{{ formatDate(coupon.startsAt) }}</span>
              <span v-if="coupon.endsAt">- {{ formatDate(coupon.endsAt) }}</span>
              <div v-if="!coupon.startAt && !coupon.endsAt">{{ $t('Forever') }}</div>
            </div>
            <div class="actions">
              <UIButton
                tiny
                icon="edit"
                :to="{ name: 'coupon-show', params: { couponId: coupon.id } }"
              >{{ $t("edit") }}</UIButton>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';

@Component({
  components: {
    UIButton,
    UIIcon,
    UIRowIndexLoader,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
})
export default class CouponIndexRoute extends Vue {
  get isLoading () {
    return this.$store.state.promos.isFetchingPromos && this.coupons.length === 0;
  }

  get noCoupons () {
    return !this.$store.state.promos.isFetchingPromos && this.coupons.length === 0;
  }

  get coupons () {
    return this.$store.getters['entities/Coupon'](({ location }) => location.id === this.locationId);
  }

  get activeCoupons () {
    return this.coupons.filter(coupon => coupon.active).sort((a, b) => {
      if (!a.endsAt && !b.endsAt) return 0;
      if (!a.endsAt) return 1;
      if (!b.endsAt) return -1;
      return Date.parse(a.endsAt) - Date.parse(b.endsAt);
    });
  }

  get inactiveCoupons () {
    return this.coupons.filter(coupon => !coupon.active).sort((a, b) => {
      if (!a.endsAt && !b.endsAt) return 0;
      if (!a.endsAt) return 1;
      if (!b.endsAt) return -1;
      return Date.parse(a.endsAt) - Date.parse(b.endsAt);
    });
  }

  created () {
    this.$store.commit('setTitle', this.$t('Coupons'));
  }

  mounted () {
    this.$store.dispatch('getLocationCoupons', this.locationId);
  }

  formatDate (date) {
    return moment(date).format('LL');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.coupon-index {
  @apply --container;

  & .header-bar {
    margin: var(--spacingMd) 0 var(--spacingSm) 0;
    text-align: right;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & button {
      @apply --buttonPrimary;

      display: inline-block;
      width: auto;
    }
  }

  & h2 {
    @apply --f5;
    margin: var(--spacingMd) 0;
  }

  & .coupongridheader {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    grid-gap: var(--spacingSm);
    padding: 0 var(--spacingSm);
    margin-bottom: var(--spacingSm);

    @media (--tablet) {
      grid-gap: var(--spacingMd);
      padding: 0 var(--spacingAltMd);
      grid-template-columns: 15rem 1fr 1fr 1fr 5.5rem;

      & > * {
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }
    }
  }

  & .coupongrid {
    display: grid;
    grid-gap: var(--spacingSm);

    & .couponcard {
      background-color: var(--colorWhite);
      box-shadow: var(--shadowSm);
      border-radius: var(--radius);

      display: grid;
      grid-template-columns: auto 5.5rem;
      align-items: center;
      grid-gap: var(--spacingSm);
      padding: var(--spacingSm);

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        padding: var(--spacingAltSm) var(--spacingAltMd);
        grid-template-columns: 15rem 1fr 1fr 1fr 5.5rem;

        & > * {
          text-align: center;

          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }

  & .active {
    margin-bottom: var(--spacingLg);
  }
}
</style>
