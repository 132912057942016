<template>
  <div class="start-end-date" v-show="timing === 'dates'">
    <p class="f-instructions">{{ $t('Both Start Date and End Date are optional.') }}</p>
    <div class="dates">
      <div class="start-date">
        <UIDatepicker
          :locale="location.locale"
          :value="promo.startsAt"
          :title="$t('Start Date')"
          @update="(value) => updateDate('startsAt', value)"
        />
        <UIButton
          tiny
          v-if="promo.startsAt"
          @click="clearDate('startsAt')"
          class="clear"
        >{{ $t("Clear") }}</UIButton>
      </div>
      <div class="end-date">
        <UIDatepicker
          :locale="location.locale"
          :value="promo.endsAt"
          :title="$t('End Date')"
          @update="(value) => updateDate('endsAt', value)"
        />
        <UIButton
          tiny
          v-if="promo.endsAt"
          @click="clearDate('endsAt')"
          class="clear"
        >{{ $t("Clear") }}</UIButton>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIDatepicker from '@/core/shared/components/ui/UIDatepicker';

@Component({
  components: {
    UIDatepicker,
    UIButton,
  },
  props: {
    promo: {
      type: Object,
      required: true,
    },
    timing: {
      type: String,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },
})
export default class StartEndDate extends Vue {
  updateDate (field, value) {
    const tzOffsetMoment = moment.tz(value, this.location.timezone);
    if (field === 'endsAt') tzOffsetMoment.set({ hour: 23, minute: 59, second: 0 });
    this.$emit('change:timing', field, tzOffsetMoment.format());
  }

  clearDate (field) {
    this.$emit('change:timing', field, null);
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.start-end-date {
  padding-top: var(--spacingSm);
  & .dates {
    margin: var(--spacingSm) 0 0 0;
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--desktop) {
      grid-gap: var(--spacingMd);
    }
  }
  & .clearbtn {
    margin-top: var(--spacingXS);
  }
}
</style>
