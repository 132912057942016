<template>
  <ValidationObserver tag="div" v-slot="{ pristine, valid }" class="coupon-show" v-if="show">
    <div class="main-content">
      <div class="top-actions">
        <BreadcrumbNavConnected class="breadcrumb"/>
        <button v-if="coupon.id" @click="deleteCoupon" class="delete-coupon">
          <svg>
            <use xlink:href="#icon-trash"></use>
          </svg>
          <span>{{ $t("Delete Coupon") }}</span>
        </button>
      </div>
      <ContentBlockGroup>
        <content-block>
          <div slot="instructions">
            <h5>{{ $t('Coupon Details') }}</h5>
          </div>
          <PromoDetails :promo="coupon" ref="promoDetails">
            <template>
              <UIInput
                :title="$t('Code')"
                :instructions="$t('Customers will enter this code during checkout to receive their coupon.')"
                v-model="coupon.code"
                required
                rules="required"
              />
            </template>
          </PromoDetails>
        </content-block>

        <content-block :topJoin="true">
          <div slot="instructions">
            <h5>{{ $t('Coupon Timing') }}</h5>
          </div>
          <PromoTiming
            :promo="coupon"
            :location="location"
            @change:timing="changeTiming"
          />
        </content-block>

        <content-block :topJoin="true" :required="true">
          <div slot="instructions">
            <h5>{{ $t('Coupon Parameters') }}</h5>
          </div>
          <PromoParameters
            :promo="coupon"
            :experiences="experiences"
            @change:experienceId="changeExperienceId"
            @input:minStations="inputMinStations"
          />
        </content-block>
      </ContentBlockGroup>
    </div>
    <bottom-bar
      @save="storeCoupon"
      :valid="!pristine && valid"
      :saving="saving"
      :backRouterLink="{ name: 'coupon-index' }"
    />
  </ValidationObserver>
</template>

<script>
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import Component, { mixins } from 'vue-class-component';

import ContentBlock from '@/core/shared/components/ContentBlock';
import BottomBar from '@/core/shared/components/BottomBar';

import { isEqual, isEmpty } from 'lodash-es';
import { ValidationObserver } from 'vee-validate';

import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import StartEndDate from '../components/StartEndDate';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';
import ContentBlockGroup from '@/core/shared/components/ContentBlockGroup';
import PromoDetails from '../components/PromoDetails';
import PromoTiming from '../components/PromoTiming';
import PromoParameters from '../components/PromoParameters';

@Component({
  props: {
    couponId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    ContentBlock,
    ContentBlockGroup,
    BreadcrumbNavConnected,
    BottomBar,
    StartEndDate,
    UIInput,
    UISelect,
    UISwitch,
    UIButton,
    ValidationObserver,
    PromoDetails,
    PromoTiming,
    PromoParameters,
  },
})
export default class CouponShowRoute extends mixins(FocusModeMixin) {
  defaultCoupon = {
    type: 'percent',
    active: false,
    code: 'COUPON',
    amount: 0,
    minStations: 1,
    startsAt: null,
    endsAt: null,
    experience: {
      id: null,
    },
    location: {
      id: this.locationId,
    },
  };

  saving = false;
  show = false;
  couponChanges = {};

  get coupon () {
    return new Proxy({ ...this.couponInStore, ...this.couponChanges }, {
      set: (object, property, value) => {
        if (isEqual(this.couponInStore[property], value)) {
          this.$delete(this.couponChanges, property, value);
        } else {
          this.$set(this.couponChanges, property, value);
        }
        return true;
      },
    });
  }

  get couponInStore () {
    return (this.couponId === 'new') ? this.defaultCoupon : this.$store.getters['entities/Coupon'](this.couponId);
  }

  get experiences () {
    const experiences = this.$store.getters['entities/Experience'](({ location }) => location.id === this.locationId);
    if (this.couponId === 'new' && experiences.length > 0) {
      this.couponChanges.experience.id = experiences[0].id;
    }
    return experiences;
  }

  get location () {
    return this.$store.getters['entities/Location'](this.locationId);
  }

  async created () {
    if (isEmpty(this.couponInStore)) await this.$store.dispatch('getLocationCoupons', this.locationId);
    this.show = true;

    let title;
    if (this.couponId === 'new') {
      this.couponChanges = this.defaultCoupon;
      title = this.$t('New Coupon');
    } else {
      this.couponChanges.id = this.coupon.id;
      title = this.$t('Edit Coupon');
    }

    this.$store.commit('setTitle', title);

    this.setBreadcrumb({
      indexTitle: this.$tc('Coupon', 2),
      editTitle: title,
      indexRouteName: 'coupon-index',
    });
  }

  changeExperienceId (id) {
    this.coupon.experience = { id };
  }

  inputMinStations (minStations) {
    this.coupon.minStations = minStations;
  }

  async storeCoupon () {
    if (this.timing === 'always') {
      this.coupon.startsAt = null;
      this.coupon.endsAt = null;
    }

    this.coupon.amount = this.$refs.promoDetails.formatAmountForStore();

    try {
      this.saving = true;
      await this.$store.dispatch('storeCoupon', this.couponChanges);
      this.$store.commit('setFlash', {
        message: `${this.coupon.code} ${this.$t('saved')}`,
        type: 'success',
      });
      this.$router.push({ name: 'coupon-index' });
    } finally {
      this.saving = false;
    }
  }

  changeTiming (property, value) {
    this.coupon[property] = value;
  }

  deleteCoupon () {
    this.$store.commit('setConfirm', {
      show: true,
      message: this.$t('Are you sure you would like to delete this coupon?'),
      icon: 'icon-trash',
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: async () => {
            await this.$store.dispatch('deleteCoupon', this.coupon);
            this.$router.push({ name: 'coupon-index' });
          },
        },
      ],
    });
  }
}
</script>

<style scope lang="postcss">
@import "core/shared/styles";

.coupon-show {
  & .main-content {
    @apply --container;
  }
  & .breadcrumb {
    margin-right: auto;
  }
  & .top-actions {
    display: flex;
    margin: var(--spacingMd) 0 var(--spacingSm) 0;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & .delete-coupon {
      @apply --buttonTinyPrimary;

      margin: 0;
      margin-left: auto;
      width: auto;
    }
  }
}
</style>
