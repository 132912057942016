<template>
  <RouterView />
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
})
export default class PromosRoute extends Vue {
  created () {
    this.$store.dispatch('getExperiencesForPromos', this.locationId);
  }
}
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';
</style>
