<template>
  <ValidationObserver tag="div" v-slot="{ pristine, valid }" class="discount-show" v-if="show">
    <div class="main-content">
      <div class="top-actions">
        <BreadcrumbNavConnected class="breadcrumb"/>
        <button v-if="discount.id" @click="deleteDiscount" class="delete-discount">
          <svg>
            <use xlink:href="#icon-trash"></use>
          </svg>
          <span>{{ $t("Delete Discount") }}</span>
        </button>
      </div>
      <ContentBlockGroup>
        <content-block>
          <div slot="instructions">
            <h5>{{ $t('Discount Details') }}</h5>
          </div>
          <PromoDetails :promo="discount" ref="promoDetails">
            <template>
              <UIInput
                :title="$t('Name')"
                :instructions="$t('For internal reference only.')"
                v-model="discount.title"
                required
                rules="required"
              />
            </template>
          </PromoDetails>
        </content-block>

        <content-block :topJoin="true">
          <div slot="instructions">
            <h5>{{ $t('Discount Timing') }}</h5>
          </div>
          <PromoTiming
            :promo="discount"
            :location="location"
            @change:timing="changeTiming"
          />
        </content-block>

        <content-block :topJoin="true" :required="true">
          <div slot="instructions">
            <h5>{{ $t('Discount Parameters') }}</h5>
          </div>
          <PromoParameters
            :promo="discount"
            :experiences="experiences"
            @change:experienceId="changeExperienceId"
            @input:minStations="inputMinStations"
          />
        </content-block>
      </ContentBlockGroup>
    </div>
    <bottom-bar
      @save="storeDiscount"
      :valid="!pristine && valid"
      :saving="saving"
      :backRouterLink="{ name: 'discount-index' }"
    />
  </ValidationObserver>
</template>

<script>
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import Component, { mixins } from 'vue-class-component';

import ContentBlock from '@/core/shared/components/ContentBlock';
import BottomBar from '@/core/shared/components/BottomBar';

import { isEqual, isEmpty } from 'lodash-es';
import { ValidationObserver } from 'vee-validate';

import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import StartEndDate from '../components/StartEndDate';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIButton from '@/core/shared/components/ui/UIButton';
import ContentBlockGroup from '@/core/shared/components/ContentBlockGroup';
import PromoDetails from '../components/PromoDetails';
import PromoTiming from '../components/PromoTiming';
import PromoParameters from '../components/PromoParameters';

@Component({
  props: {
    discountId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    ContentBlock,
    ContentBlockGroup,
    BreadcrumbNavConnected,
    BottomBar,
    StartEndDate,
    UIInput,
    UIButton,
    PromoDetails,
    PromoTiming,
    PromoParameters,
    ValidationObserver,
  },
})
export default class DiscountShowRoute extends mixins(FocusModeMixin) {
  defaultDiscount = {
    type: 'percent',
    active: false,
    title: '',
    amount: 0,
    minStations: 1,
    startsAt: null,
    endsAt: null,
    experience: {
      id: null,
    },
    location: {
      id: this.locationId,
    },
  };

  saving = false;
  show = false;
  discountChanges = {};

  get discount () {
    return new Proxy({ ...this.discountInStore, ...this.discountChanges }, {
      set: (object, property, value) => {
        if (isEqual(this.discountInStore[property], value)) {
          this.$delete(this.discountChanges, property, value);
        } else {
          this.$set(this.discountChanges, property, value);
        }
        return true;
      },
    });
  }

  get discountInStore () {
    return (this.discountId === 'new') ? this.defaultDiscount : this.$store.getters['entities/Discount'](this.discountId);
  }

  get experiences () {
    const experiences = this.$store.getters['entities/Experience'](({ location }) => location.id === this.locationId);
    if (this.discountId === 'new' && experiences.length > 0) {
      this.discountChanges.experience.id = experiences[0].id;
    }
    return experiences;
  }

  get location () {
    return this.$store.getters['entities/Location'](this.locationId);
  }

  async created () {
    if (isEmpty(this.discountInStore)) await this.$store.dispatch('getLocationDiscounts', this.locationId);
    this.show = true;

    let title;
    if (this.discountId === 'new') {
      this.discountChanges = this.defaultDiscount;
      title = this.$t('New Discount');
    } else {
      this.discountChanges.id = this.discount.id;
      title = this.$t('Edit Discount');
    }

    if (this.discountInStore.startsAt) {
      this.timing = 'dates';
    }

    this.$store.commit('setTitle', title);

    this.setBreadcrumb({
      indexTitle: this.$tc('Discount', 2),
      editTitle: title,
      indexRouteName: 'discount-index',
    });
  }

  changeExperienceId (id) {
    this.discount.experience = { id };
  }

  inputMinStations (minStations) {
    this.discount.minStations = minStations;
  }

  async storeDiscount () {
    if (this.timing === 'always') {
      this.discount.startsAt = null;
      this.discount.endsAt = null;
    }

    this.discount.amount = this.$refs.promoDetails.formatAmountForStore();

    try {
      this.saving = true;
      await this.$store.dispatch('storeDiscount', this.discountChanges);
      this.$store.commit('setFlash', {
        message: `${this.discount.title} ${this.$t('saved')}`,
        type: 'success',
      });
      this.$router.push({ name: 'discount-index' });
    } finally {
      this.saving = false;
    }
  }

  changeTiming (property, value) {
    this.discount[property] = value;
  }

  deleteDiscount () {
    this.$store.commit('setConfirm', {
      show: true,
      message: this.$t('Are you sure you would like to delete this discount?'),
      icon: 'icon-trash',
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: async () => {
            await this.$store.dispatch('deleteDiscount', this.discount);
            this.$router.push({ name: 'discount-index' });
          },
        },
      ],
    });
  }
}
</script>

<style scope lang="postcss">
@import "core/shared/styles";

.discount-show {
  & .main-content {
    @apply --container;
  }
  & .breadcrumb {
    margin-right: auto;
  }
  & .top-actions {
    display: flex;
    margin: var(--spacingMd) 0 var(--spacingSm) 0;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & .delete-discount {
      @apply --buttonTinyPrimary;

      margin: 0;
      margin-left: auto;
      width: auto;
    }
  }

  & .timing {
    display: flex;
    & > *:first-child {
      margin-right: var(--spacingSm);
    }
  }

  & .details,
  & .parameters {
    display: grid;
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--desktop) {
      grid-gap: var(--spacingMd);
    }
  }
}
</style>
